<template>
  <BaseInput
    :value="values[field.id]"
    size="is-small"
    type="text"
    :disabled="field.properties.basic.isDisabled"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    :mask="ibanMask"
    @input="handleUpdate($event)"
    v-on="fieldEvents"
  />
</template>

<script >
import axios from 'axios';
import { debounce } from 'lodash';
import BaseInput from './generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const ibanMask = {
    blocks: [4, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4],
    uppercase: true
  };
  const handleUpdate = value => {
    if (props.field.properties.basic.hasIbanApiValidation) {
      checkIBAN(value);
    }
    emit('update', {
      value: value?.toUpperCase()
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  const checkIBAN = debounce(async value => {
    const response = await axios({
      method: 'GET',
      url: `${window.env.VUE_APP_APP_SERVICE_DOMAIN}/iban`,
      params: {
        iban: value
      }
    });
    console.log(response);
    if (!response.data.is_valid) {
      emit('error', {
        message: 'Bitte geben Sie eine gültige IBAN an'
      });
    } else {
      emit('success', {
        message: ''
      });
    }
  }, 500);
  return {
    ibanMask,
    handleUpdate
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
